var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { staticClass: "main_layer py-4", attrs: { fluid: "" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "p",
                      {
                        staticClass:
                          "fs-52 fw-600 white-color mb-4 mt-2 if-tablet",
                      },
                      [_vm._v(_vm._s(_vm.title))]
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "fs-14 greyBg-color mb-1" }, [
                      _vm._v(
                        "«" + _vm._s(_vm.currentOrganization.short_name) + "»"
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("p", { staticClass: "fs-14 greyBg-color" }, [
                      _vm._v(
                        " Пользователь: " + _vm._s(_vm.currentUser.name) + " "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }