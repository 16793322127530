var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.id } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { md: "3" } }, [
            _c(
              "p",
              {
                staticClass:
                  "fs-14 grey-color fw-700 d-flex align-items-baseline",
              },
              [_vm._v(_vm._s(_vm.title))]
            ),
          ]),
          _c("b-col", { attrs: { md: "9" } }, [
            _c(
              "div",
              { staticClass: "text-right mb-2" },
              [
                _c(
                  "b-dropdown",
                  {
                    staticClass: "ml-auto",
                    attrs: { text: "Добавить адрес", variant: "custom-green" },
                  },
                  [
                    _c(
                      "b-dropdown-item",
                      { on: { click: _vm.showAddressBookSelect } },
                      [_vm._v("Из адресной книги")]
                    ),
                    _c(
                      "b-dropdown-item",
                      { on: { click: _vm.showFiasSelect } },
                      [_vm._v("Ввести вручную")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { attrs: { width: "1" } }),
                    _c("th", [_vm._v("Адрес")]),
                    _c("th", { attrs: { width: "1" } }),
                  ]),
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.addresses, function (addr, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1) + ".")]),
                        _c("td", [_vm._v(_vm._s(addr))]),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { background: "transparent" },
                                attrs: { variant: "light", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeAddress(index)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/trash_icon.svg",
                                    alt: "trash_icon",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    _vm.addresses && _vm.addresses.length === 0
                      ? _c("tr", [
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: "3" },
                            },
                            [_vm._v("Не добавлено адресов")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row" },
        [_c("b-col", { attrs: { sm: "12" } })],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { title: "Адрес", "hide-footer": "", centered: "" },
          model: {
            value: _vm.showAddress,
            callback: function ($$v) {
              _vm.showAddress = $$v
            },
            expression: "showAddress",
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "multiselect",
                    {
                      attrs: {
                        placeholder: "Выберите адрес",
                        "deselect-label": "Отменить",
                        "selected-label": "Выбрано",
                        "select-label": "Выбрать",
                        "track-by": "short_address",
                        label: "short_address",
                        options: _vm.addresses,
                        searchable: true,
                        "allow-empty": false,
                      },
                      model: {
                        value: _vm.address,
                        callback: function ($$v) {
                          _vm.address = $$v
                        },
                        expression: "address",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "noOptions" }, slot: "noOptions" },
                        [_vm._v("Нет данных.")]
                      ),
                      _c(
                        "span",
                        { attrs: { slot: "noResult" }, slot: "noResult" },
                        [
                          _vm._v(
                            "Записей, соответствующих вашему запросу, не найдено."
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "py-2" },
            [
              _c(
                "b-col",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-green" },
                      on: { click: _vm.addBookAddress },
                    },
                    [_vm._v("Добавить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { title: "Адрес", "hide-footer": "", centered: "" },
          model: {
            value: _vm.showFias,
            callback: function ($$v) {
              _vm.showFias = $$v
            },
            expression: "showFias",
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c("VueSuggestions", {
                    staticClass: "form-control",
                    attrs: {
                      model: _vm.fiasAddress,
                      placeholder: "Начните вводить",
                      options: _vm.suggestionOptions,
                    },
                    on: {
                      "update:model": function ($event) {
                        _vm.fiasAddress = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "py-2 mt-3" },
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase",
                      attrs: { variant: "custom-green" },
                      on: { click: _vm.addFiasAddress },
                    },
                    [_vm._v("Добавить")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }