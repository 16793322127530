<template>
    <div class="purchases_create">
        <purchase-header-block :title="purchase.was_canceled ? `Редактирование извещения № ${purchase.reg_number} 223-ФЗ` : 'Объявление о закупочной сессии 223-ФЗ'" />
        <b-container class="pb-5 position-relative purchase_session" style="background-color: #f5f5f7">
            <b-tabs v-model="tabIndex" class="w-100" content-class="mt-3">
                <b-tab active>
                    <template slot="title">
                        <purchase-tab-block number="01" title="Информация о заказчике" />
                    </template>
                    <h3 class="fs-32 grey-color my-4">Информация о заказчике</h3>

                    <form-row-show-text :value="currentOrganization.full_name" label="Полное наименование" />
                    <form-row-show-text :value="currentOrganization.short_name" label="Сокращенное наименование" />
                    <form-row-show-text :value="currentOrganization.inn" label="ИНН" />
                    <form-row-show-text v-if="currentOrganization.kpp" :value="currentOrganization.kpp" label="КПП" />
                    <form-row-show-text v-if="currentOrganization.ogrn" :value="currentOrganization.ogrn" label="ОГРН" />
                    <form-row-show-text :value="currentOrganization.post_address" label="Адрес" />
                    <form-row-edit-text v-model="purchase.add_contact_info" label="Дополнительная контактная информация" />
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <purchase-tab-block :errors="tab2errs" number="02" title="Условия закупки и поставки" />
                    </template>

                    <h3 class="fs-32 grey-color my-4">Условия закупки</h3>
                    <form-row-single-select
                        v-model="purchase.purchase_category_id"
                        :disabled="(purchase.was_canceled || purchase.is_copy) && purchase.purchase_category_id && !$v.purchase.purchase_category_id.$anyDirty"
                        :options="purchaseCategories"
                        :v="$v.purchase.purchase_category_id"
                        label="Предмет закупки"
                        placeholder="Выберите предмет закупки" />
                    <form-row-single-select
                        v-model="purchase.purchase_length_type_id"
                        :disabled="purchase.was_canceled"
                        :options="FZ223Constants.PurchaseLengthTypes"
                        :v="$v.purchase.purchase_length_type_id"
                        label="Продолжительность закупочной сессии"
                        placeholder="Выберите продолжительность закупочной сессии" />
                    <form-row-edit-number v-model="purchase.purchase_length" :label="`Продолжительность закупочной сессии${currentPurchaseLengthTypePostfix}`" :max-value="currentPurchaseLengthTypeMaxLength" :min-value="currentPurchaseLengthTypeMinLength" />
                    <form-row-single-select v-model="purchase.payment_type_id" :options="paymentTypes" :v="$v.purchase.payment_type_id" label="Вид оплаты" label-field="text" placeholder="Выберите вид оплаты" track-by="value" />
                    <form-row-edit-date v-model="purchase.planned_contract_date" :min-date="new Date().toJSON().slice(0, 10)" :v="$v.purchase.planned_contract_date" label="Планируемая дата заключения договора" />
                    <form-row-single-select v-model="purchase.payment_term_id" :options="paymentTerms" :v="$v.purchase.payment_term_id" label="Условия оплаты" label-field="text" placeholder="Выберите условия оплаты" track-by="value" />
                    <form-row-edit-date v-model="purchase.planned_contract_execution_date" :disabled="!purchase.planned_contract_date" :min-date="purchase.planned_contract_date" :v="$v.purchase.planned_contract_execution_date" label="Планируемая дата исполнения договора" />
                    <form-row-edit-textarea v-model="purchase.add_termination_info" label="Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации" />

                    <h3 class="fs-32 grey-color my-4">Требования к поставщикам</h3>
                    <form-row-edit-checkbox v-model="purchase.only_not_rnp" label="Отсутствие в реестре недобросовестных поставщиков" />
                    <form-row-edit-checkbox v-model="purchase.only_msp" label="Только для СМП" />

                    <h3 class="fs-32 grey-color my-4">Условия поставки</h3>
                    <form-row-edit-addresses v-model="purchase.delivery_addresses" title="Адрес для доставки/самовывоза" />
                    <form-row-edit-text v-model="purchase.add_delivery_schedule_info" label="График поставки товаров (выполнения работ, оказания услуг)" />
                    <form-row-edit-date v-model="purchase.max_delivery_date" :disabled="!purchase.planned_contract_date" :min-date="purchase.planned_contract_date" :v="$v.purchase.max_delivery_date" label="Максимальный срок поставки товаров (выполнения работ, оказания услуг)" />
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <purchase-tab-block :errors="tab3errs" number="03" title="Спецификация и документы" />
                    </template>
                    <form-row-single-select v-model="purchase.contract_format_id" :options="contractFormats" :v="$v.purchase.contract_format_id" label="Формат заключения договора" placeholder="Выберите формат заключения договора" />
                    <form-row-edit-text v-model="purchase.contract_system_name" label="Оператор ЭДО" v-if="purchase.contract_format_id === 1" :v="$v.purchase.contract_system_name" />
                    <form-row-specification-table v-model="purchase.specs" :spec-error-message="specErrorMessage" :spec-errors="specErrors" :spec-sum="specSum" :v="$v.purchase.specs" with-count-edit with-price />
                    <form-row-single-document-upload v-model="purchase.main_file" :v="$v.purchase.main_file" required title="Проект договора" />
                    <form-row-multiple-document-upload v-model="purchase.files" title="Дополнительные документы" />
                </b-tab>
            </b-tabs>
            <b-row class="my-3 mt-5">
                <b-col>
                    <b-button v-if="tabIndex === 0" class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="onCancel">Отменить</b-button>
                    <b-button v-if="tabIndex > 0" class="mx-2 text-uppercase" variant="custom-outline-secondary" @click="tabPrevious">Назад</b-button>
                    <b-button v-if="tabIndex < 2" class="text-uppercase mx-2" variant="custom-green" @click="tabNext">Далее</b-button>
                    <b-button class="text-uppercase mx-2" variant="custom-danger" @click="saveDraft">Сохранить черновик</b-button>
                    <b-button v-if="tabIndex === 2" :disabled="!isValid || !$store.getters.getCanSign" class="text-uppercase mx-2" variant="custom-green" @click="validateForm">Подписать и сохранить</b-button>
                    <span v-if="tabIndex === 2 && !$store.getters.getCanSign" class="text-danger pt-1">Подписание возможно только при авторизации при помощи ЭЦП</span>
                </b-col>
            </b-row>
            <b-modal ref="sign-form-modal" v-model="showSignModal" centered no-close-on-backdrop no-close-on-esc scrollable size="lg" title="Подписать заявку" @close="cancelSignModal">
                <div class="d-block text-left">
                    <b-tabs content-class="mt-3 px-3">
                        <b-tab active>
                            <template slot="title">
                                <span class="system_link-style fs-16">Форма</span>
                            </template>
                            <p class="fs-32 grey-color my-4">Заказчик</p>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Наименование заказчика</b-col>
                                <b-col class="fs-14 grey-color" md="9">{{ currentOrganization.full_name }}</b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">ИНН Заказчика</b-col>
                                <b-col class="fs-14 grey-color" md="9">{{ currentOrganization.inn }}</b-col>
                            </b-row>
                            <b-row v-if="currentOrganization.kpp" class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">КПП Заказчика</b-col>
                                <b-col class="fs-14 grey-color" md="9">{{ currentOrganization.kpp }}</b-col>
                            </b-row>
                            <b-row v-if="currentOrganization.ogrn" class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">ОГРН Заказчика</b-col>
                                <b-col class="fs-14 grey-color" md="9">{{ currentOrganization.ogrn }}</b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Адрес</b-col>
                                <b-col class="fs-14 grey-color" md="9">{{ currentOrganization.post_address }}</b-col>
                            </b-row>
                            <b-row class="py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Дополнительная контактная информация</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchase.add_contact_info }}
                                </b-col>
                            </b-row>

                            <p class="fs-32 grey-color my-4">Закупка</p>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Предмет закупки</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchaseCategoryName }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Вид оплаты</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ paymentTypeName }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Условия оплаты</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ paymentTermName }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Время продолжительности закупочной сессии (в часах)</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchase.purchase_length }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Планируемая дата заключения договора</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ getDateFormat(purchase.planned_contract_date) }}
                                </b-col>
                            </b-row>
                            <b-row v-if="!!purchase.planned_contract_execution_date" class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3">Планируемая дата исполнения договора</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ getDateFormat(purchase.planned_contract_execution_date) }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации </b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchase.add_termination_info }}
                                </b-col>
                            </b-row>
                            <b-row class="py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Начальная максимальная цена контракта (руб.)</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ $formatPrice(purchase.start_price) }}
                                </b-col>
                            </b-row>

                            <p class="fs-32 grey-color my-4">Требования к поставщикам</p>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Отсутствие в реестре недобросовестных поставщиков</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchase.only_not_rnp ? 'ДА' : 'НЕТ' }}
                                </b-col>
                            </b-row>
                            <b-row class="py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Только для СМП</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchase.only_msp ? 'ДА' : 'НЕТ' }}
                                </b-col>
                            </b-row>
                            <p class="fs-32 grey-color my-4">Условия поставки</p>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Адреса для доставки</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    <div v-for="(addr, index) in purchase.delivery_addresses" :key="index">
                                        - <strong>{{ addr }}</strong>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> График поставки товаров (выполнения работ, оказания услуг)</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ purchase.add_delivery_schedule_info }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Маскимальный срок поставки товаров (выполнения работ, оказания услуг)</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    {{ getDateFormat(purchase.max_delivery_date) }}
                                </b-col>
                            </b-row>
                            <b-row class="border-bottom py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Спецификации</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    <div v-for="(sp, index) in purchase.specs" :key="index">
                                        - <strong>{{ sp.name }} {{ sp.count }} {{ sp.okei.text }}</strong>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="py-3">
                                <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Документы</b-col>
                                <b-col class="fs-14 grey-color" md="9">
                                    <div v-if="!!purchase.main_file">
                                        - <strong>{{ purchase.main_file.name }} ({{ purchase.main_file.path }})</strong>
                                    </div>
                                    <div v-for="(dc, index) in purchase.files" :key="index">
                                        - <strong>{{ dc.name }} ({{ dc.path }})</strong>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                <span class="system_link-style fs-16">Xml представление</span>
                            </template>
                            <b-form-textarea v-if="!isSignedXml" v-model="formXml" disabled rows="20"></b-form-textarea>
                            <b-form-textarea v-if="isSignedXml" v-model="signedFormXml" disabled rows="20"></b-form-textarea>
                        </b-tab>
                    </b-tabs>
                    <b-row class="py-5 px-3">
                        <b-col class="fs-14 grey-color d-flex align-items-center" md="3"> Сертификат</b-col>
                        <b-col class="fs-14 grey-color" md="9">
                            <b-input :value="currentCertSubject" disabled></b-input>
                        </b-col>
                    </b-row>
                </div>
                <div slot="modal-footer" class="w-100 px-3">
                    <b-button :disabled="isDataSending" class="mr-2 text-uppercase" variant="custom-outline-secondary" @click="cancelSignModal">Отмена</b-button>
                    <b-overlay v-if="!isSignedXml" :show="isSigningXml" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="certIndex == null" class="text-uppercase" variant="custom-green" @click="signForm">Подписать</b-button>
                    </b-overlay>
                    <b-overlay v-if="isSignedXml" :show="isDataSending" class="d-inline-block" opacity="0.6" rounded spinner-small spinner-variant="primary">
                        <b-button :disabled="certIndex == null" class="text-uppercase" variant="custom-green" @click="sendData">Отправить</b-button>
                    </b-overlay>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>

<script>
import axios from 'axios';
import CadesMixins from '@/mixins/CadesMixins';
import { required, requiredIf } from 'vuelidate/lib/validators';
import PurchaseHeaderBlock from '@/modules/fz223/components/purchase-header-block';
import FormRowShowText from '@/components/common/form-rows/form-row-show-text';
import { FZ223 } from '@/const';
import FormRowEditNumber from '@/components/common/form-rows/form-row-edit-number';
import PurchaseTabBlock from '@/modules/fz223/components/purchase-tab-block';
import FormRowSingleDocumentUpload from '@/components/common/form-rows/form-row-single-document-upload';
import FormRowEditCheckbox from '@/components/common/form-rows/form-row-edit-checkbox';
import FormRowEditAddresses from '@/components/common/form-rows/form-row-edit-addresses';
import FormRowMultipleDocumentUpload from '@/components/common/form-rows/form-row-multiple-document-upload';

export default {
    name: 'PurchaseCreate',
    components: {
        FormRowMultipleDocumentUpload,
        FormRowEditAddresses,
        FormRowEditCheckbox,
        FormRowSingleDocumentUpload,
        PurchaseTabBlock,
        FormRowEditNumber,
        FormRowShowText,
        PurchaseHeaderBlock
    },
    mixins: [CadesMixins],
    validations: {
        purchase: {
            specs: { required },
            purchase_category_id: { required },
            purchase_length_type_id: { required },
            purchase_length: { required },
            payment_type_id: { required },
            planned_contract_date: { required },
            payment_term_id: { required },
            contract_format_id: { required },
            contract_system_name: {
                requiredIf: requiredIf(function () {
                    return this.purchase.contract_format_id === 1;
                })
            },
            planned_contract_execution_date: { required },
            add_delivery_schedule_info: {},
            max_delivery_date: { required },
            main_file: { required }
        }
    },
    data() {
        return {
            isSigningXml: false,
            isDataSending: false,
            visible: [],
            tabIndex: 0,
            id: null,
            formXml: '',
            signedFormXml: null,
            isSignedXml: false,
            showSignModal: false,
            currentCertSubject: '',
            paymentTypes: [],
            paymentTerms: [],
            planned_contract_date: null,
            purchase_end: null,
            payment_deadline: null,
            showSpec: false,
            purchase: {
                purchase_category_id: null,
                purchase_length_type_id: 1,
                purchase_length: 2,
                planned_contract_date: null,
                planned_contract_execution_date: null,
                max_delivery_date: null,
                add_contact_info: '',
                add_termination_info: '',
                add_delivery_schedule_info: null,
                payment_type_id: 1,
                payment_term_id: 2,
                contract_format_id: 1,
                contract_system_name: 'В ИС',
                only_msp: false,
                only_not_rnp: false,
                start_price: 0,
                delivery_addresses: [],
                specs: [],
                files: [],
                main_file: null
            },
            purchaseCategories: [],
            fz223OrganizationInfo: null
        };
    },
    created() {
        this.getDirectories().then(() => {
            this.getDraft();
        });
    },
    methods: {
        tabNext() {
            this.tabIndex++;
            this.$scrollToTop();
        },
        tabPrevious() {
            this.tabIndex--;
            this.$scrollToTop();
        },
        cancelSignModal() {
            this.isSignedXml = false;
            this.showSignModal = false;
        },
        getDraft() {
            if (!!this.$route.params.id && this.$route.path.indexOf('create') !== -1) {
                this.$api.fz223.purchases
                    .getDraft(this.$route.params.id)
                    .then((resp) => {
                        this.id = resp.id;
                        this.purchase = resp.draft;
                    })
                    .catch(() => {
                        this.$router.back();
                    });
            }
        },
        async saveDraft() {
            this.purchase.start_price = this.specSum;
            const id = await this.$api.fz223.purchases.saveDraft({ purchase: this.purchase, id: this.id });
            if (id) {
                this.id = id;
            }
        },
        async getDirectories() {
            const responseData = await axios.all([axios.get('/directories/purchase_categories'), axios.get('/organizations/addresses'), this.$api.fz223.organizationInfo.get()]);
            this.purchaseCategories = responseData[0].data.data;
            this.addresses = responseData[1].data.data;
            this.fz223OrganizationInfo = responseData[2];
        },
        parsePhone(text) {
            if (text) return text.replace(/(\d{1})(\d{3})/, '+$1 ($2) ');
            return '-';
        },
        validateForm() {
            this.saveDraft();
            if (this.hasCertificates() === false) {
                this.loadCertificates();
            } else {
                this.showSignForm();
            }
            this.$on('certificates_loaded', () => {
                this.showSignForm();
            });
        },
        showSignForm() {
            this.$api.fz223.purchases.getXml(this.id).then((resp) => {
                this.formXml = resp.xml;
                this.showSignModal = true;
                let parsedCerts = this.$store.state.parsedCertificates;
                this.certIndex = this.$store.state.currentCertificateIndex;
                let currentParsedCert = parsedCerts[this.certIndex];
                this.currentCertSubject = currentParsedCert.subject;
            });
        },
        async signForm() {
            this.isSigningXml = true;
            this.signedFormXml = await this.$api.fz223.purchases.SignXML(this.formXml);
            this.isSignedXml = !!this.signedFormXml;
            this.isSigningXml = false;
        },
        async sendData() {
            this.isDataSending = true;
            await this.$api.fz223.purchases.store(this.id, this.signedFormXml).catch(() => {
                this.isDataSending = false;
            });
            this.onCancel();
        },
        onCancel() {
            this.$router.push({ name: 'ProviderMainCabinet' });
        }
    },
    computed: {
        FZ223Constants() {
            return FZ223;
        },
        contractFormats() {
            return this.FZ223Constants.ContractFormats;
        },
        specSum() {
            return Math.round(this.purchase.specs.reduce((acc, cv) => acc + Math.round(cv.count * cv.price * 100) / 100, 0) * 100) / 100;
        },
        currentPurchaseLengthType() {
            return this.purchase.purchase_length_type_id ? this.FZ223Constants.PurchaseLengthTypes.find((i) => i.id === this.purchase.purchase_length_type_id) : null;
        },
        currentPurchaseLengthTypePostfix() {
            return this.currentPurchaseLengthType?.postfix;
        },
        currentPurchaseLengthTypeMinLength() {
            return this.currentPurchaseLengthType?.minLength;
        },
        currentPurchaseLengthTypeMaxLength() {
            return this.currentPurchaseLengthType?.maxLength;
        },
        currentOrganization() {
            return this.$store.getters.getOrganization;
        },
        purchaseCategoryName() {
            return this.purchase.purchase_category_id ? this.purchaseCategories.find((e) => e.id === this.purchase.purchase_category_id).name : this.purchase.tru_name;
        },
        nameState() {
            return this.purchase.purchase_category_id;
        },
        pcDate() {
            return !!this.purchase.planned_contract_date;
        },
        maxDate() {
            return !!this.purchase.max_delivery_date;
        },
        currentLimit() {
            return this.fz223OrganizationInfo?.limit || 0;
        },
        costState() {
            return this.specSum > 0 && this.specSum <= this.currentLimit;
        },
        peDate() {
            return !!this.purchase.planned_contract_execution_date;
        },
        specErrors() {
            return !!this.specErrorMessage;
        },
        specErrorMessage() {
            if (this.purchase.specs.length === 0) return 'Заполните таблицу спецификаций';
            if (!this.purchase.specs.every((e) => e.count > 0)) return 'У одной или более позиций в таблице спецификации не заполнено поле "Количество"';
            if (this.specSum <= 0) return 'Сумма закупки должна быть больше 0 руб.';
            if (this.specSum > this.currentLimit) return `Превышена максимальная сумма закупки в ${this.$formatPrice(this.currentLimit)} руб.`;
            return '';
        },
        isValid() {
            return this.nameState && this.pcDate && this.maxDate && this.costState && this.peDate && !this.specErrors && !this.$v.$invalid;
        },
        paymentTypeName() {
            for (let i = 0; i < this.paymentTypes.length; i++) {
                if (this.paymentTypes[i].value === this.purchase.payment_type_id) {
                    return this.paymentTypes[i].text;
                }
            }
            return '';
        },
        paymentTermName() {
            for (let i = 0; i < this.paymentTerms.length; i++) {
                if (this.paymentTerms[i].value === this.purchase.payment_term_id) {
                    return this.paymentTerms[i].text;
                }
            }
            return '';
        },
        tab2errs() {
            return (this.nameState ? 0 : 1) + (this.pcDate ? 0 : 1) + (this.peDate ? 0 : 1) + (this.maxDate ? 0 : 1);
        },
        tab3errs() {
            return (!this.specErrors ? 0 : 1) + (this.purchase.main_file ? 0 : 1);
        }
    }
};
</script>

<style scoped>
.badge {
    position: absolute;
    top: -15px;
    right: -15px;
}

.red-star {
    color: red;
}

.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}

input::-webkit-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-moz-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input:-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::-ms-input-placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input::placeholder {
    color: #959ba4 !important;
    opacity: 0.5 !important;
}

input {
    border-color: rgba(149, 155, 164, 0.2);
}

.block_head-text {
    font-size: 32px;
    color: var(--grey-color);
    font-weight: 600;
    line-height: 48px;
    margin: 33px 0;
}

.table {
    border-collapse: collapse;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}

.end_list {
    list-style-type: none;
    padding-left: 0px;
    margin-bottom: 40px;
}

.end_list li {
    margin: 15px 0;
    font-size: 14px;
    color: var(--grey-color);
    position: relative;
}

.end_list li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -15px;
    width: 5px;
    height: 5px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: var(--green-color);
}

.head_register-list {
    color: var(--halfWhite-color);
}

@media screen and (max-width: 769px) {
    .if-tablet {
        font-size: 40px !important;
    }
}
</style>

<style>
.purchase_session {
    position: relative;
}

.purchase_session ul.nav-tabs {
    position: absolute;
    top: -150px;
    width: 100%;
    background: transparent;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.purchase_session ul.nav-tabs li {
    width: 33%;
}

.purchase_session ul.nav-tabs li a {
    border: none;
}

.purchase_session .nav-link.active {
    border-bottom: 2px solid #fff;
    background: transparent;
}

.purchase_session .nav-link.active .head_register-list {
    color: #fff;
}
</style>
