var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "purchases_create" },
    [
      _c("purchase-header-block", {
        attrs: {
          title: _vm.purchase.was_canceled
            ? `Редактирование извещения № ${_vm.purchase.reg_number} 223-ФЗ`
            : "Объявление о закупочной сессии 223-ФЗ",
        },
      }),
      _c(
        "b-container",
        {
          staticClass: "pb-5 position-relative purchase_session",
          staticStyle: { "background-color": "#f5f5f7" },
        },
        [
          _c(
            "b-tabs",
            {
              staticClass: "w-100",
              attrs: { "content-class": "mt-3" },
              model: {
                value: _vm.tabIndex,
                callback: function ($$v) {
                  _vm.tabIndex = $$v
                },
                expression: "tabIndex",
              },
            },
            [
              _c(
                "b-tab",
                { attrs: { active: "" } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("purchase-tab-block", {
                        attrs: {
                          number: "01",
                          title: "Информация о заказчике",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h3", { staticClass: "fs-32 grey-color my-4" }, [
                    _vm._v("Информация о заказчике"),
                  ]),
                  _c("form-row-show-text", {
                    attrs: {
                      value: _vm.currentOrganization.full_name,
                      label: "Полное наименование",
                    },
                  }),
                  _c("form-row-show-text", {
                    attrs: {
                      value: _vm.currentOrganization.short_name,
                      label: "Сокращенное наименование",
                    },
                  }),
                  _c("form-row-show-text", {
                    attrs: { value: _vm.currentOrganization.inn, label: "ИНН" },
                  }),
                  _vm.currentOrganization.kpp
                    ? _c("form-row-show-text", {
                        attrs: {
                          value: _vm.currentOrganization.kpp,
                          label: "КПП",
                        },
                      })
                    : _vm._e(),
                  _vm.currentOrganization.ogrn
                    ? _c("form-row-show-text", {
                        attrs: {
                          value: _vm.currentOrganization.ogrn,
                          label: "ОГРН",
                        },
                      })
                    : _vm._e(),
                  _c("form-row-show-text", {
                    attrs: {
                      value: _vm.currentOrganization.post_address,
                      label: "Адрес",
                    },
                  }),
                  _c("form-row-edit-text", {
                    attrs: { label: "Дополнительная контактная информация" },
                    model: {
                      value: _vm.purchase.add_contact_info,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "add_contact_info", $$v)
                      },
                      expression: "purchase.add_contact_info",
                    },
                  }),
                ],
                2
              ),
              _c(
                "b-tab",
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("purchase-tab-block", {
                        attrs: {
                          errors: _vm.tab2errs,
                          number: "02",
                          title: "Условия закупки и поставки",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("h3", { staticClass: "fs-32 grey-color my-4" }, [
                    _vm._v("Условия закупки"),
                  ]),
                  _c("form-row-single-select", {
                    attrs: {
                      disabled:
                        (_vm.purchase.was_canceled || _vm.purchase.is_copy) &&
                        _vm.purchase.purchase_category_id &&
                        !_vm.$v.purchase.purchase_category_id.$anyDirty,
                      options: _vm.purchaseCategories,
                      v: _vm.$v.purchase.purchase_category_id,
                      label: "Предмет закупки",
                      placeholder: "Выберите предмет закупки",
                    },
                    model: {
                      value: _vm.purchase.purchase_category_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_category_id", $$v)
                      },
                      expression: "purchase.purchase_category_id",
                    },
                  }),
                  _c("form-row-single-select", {
                    attrs: {
                      disabled: _vm.purchase.was_canceled,
                      options: _vm.FZ223Constants.PurchaseLengthTypes,
                      v: _vm.$v.purchase.purchase_length_type_id,
                      label: "Продолжительность закупочной сессии",
                      placeholder:
                        "Выберите продолжительность закупочной сессии",
                    },
                    model: {
                      value: _vm.purchase.purchase_length_type_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_length_type_id", $$v)
                      },
                      expression: "purchase.purchase_length_type_id",
                    },
                  }),
                  _c("form-row-edit-number", {
                    attrs: {
                      label: `Продолжительность закупочной сессии${_vm.currentPurchaseLengthTypePostfix}`,
                      "max-value": _vm.currentPurchaseLengthTypeMaxLength,
                      "min-value": _vm.currentPurchaseLengthTypeMinLength,
                    },
                    model: {
                      value: _vm.purchase.purchase_length,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "purchase_length", $$v)
                      },
                      expression: "purchase.purchase_length",
                    },
                  }),
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.paymentTypes,
                      v: _vm.$v.purchase.payment_type_id,
                      label: "Вид оплаты",
                      "label-field": "text",
                      placeholder: "Выберите вид оплаты",
                      "track-by": "value",
                    },
                    model: {
                      value: _vm.purchase.payment_type_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "payment_type_id", $$v)
                      },
                      expression: "purchase.payment_type_id",
                    },
                  }),
                  _c("form-row-edit-date", {
                    attrs: {
                      "min-date": new Date().toJSON().slice(0, 10),
                      v: _vm.$v.purchase.planned_contract_date,
                      label: "Планируемая дата заключения договора",
                    },
                    model: {
                      value: _vm.purchase.planned_contract_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "planned_contract_date", $$v)
                      },
                      expression: "purchase.planned_contract_date",
                    },
                  }),
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.paymentTerms,
                      v: _vm.$v.purchase.payment_term_id,
                      label: "Условия оплаты",
                      "label-field": "text",
                      placeholder: "Выберите условия оплаты",
                      "track-by": "value",
                    },
                    model: {
                      value: _vm.purchase.payment_term_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "payment_term_id", $$v)
                      },
                      expression: "purchase.payment_term_id",
                    },
                  }),
                  _c("form-row-edit-date", {
                    attrs: {
                      disabled: !_vm.purchase.planned_contract_date,
                      "min-date": _vm.purchase.planned_contract_date,
                      v: _vm.$v.purchase.planned_contract_execution_date,
                      label: "Планируемая дата исполнения договора",
                    },
                    model: {
                      value: _vm.purchase.planned_contract_execution_date,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase,
                          "planned_contract_execution_date",
                          $$v
                        )
                      },
                      expression: "purchase.planned_contract_execution_date",
                    },
                  }),
                  _c("form-row-edit-textarea", {
                    attrs: {
                      label:
                        "Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации",
                    },
                    model: {
                      value: _vm.purchase.add_termination_info,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "add_termination_info", $$v)
                      },
                      expression: "purchase.add_termination_info",
                    },
                  }),
                  _c("h3", { staticClass: "fs-32 grey-color my-4" }, [
                    _vm._v("Требования к поставщикам"),
                  ]),
                  _c("form-row-edit-checkbox", {
                    attrs: {
                      label:
                        "Отсутствие в реестре недобросовестных поставщиков",
                    },
                    model: {
                      value: _vm.purchase.only_not_rnp,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "only_not_rnp", $$v)
                      },
                      expression: "purchase.only_not_rnp",
                    },
                  }),
                  _c("form-row-edit-checkbox", {
                    attrs: { label: "Только для СМП" },
                    model: {
                      value: _vm.purchase.only_msp,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "only_msp", $$v)
                      },
                      expression: "purchase.only_msp",
                    },
                  }),
                  _c("h3", { staticClass: "fs-32 grey-color my-4" }, [
                    _vm._v("Условия поставки"),
                  ]),
                  _c("form-row-edit-addresses", {
                    attrs: { title: "Адрес для доставки/самовывоза" },
                    model: {
                      value: _vm.purchase.delivery_addresses,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "delivery_addresses", $$v)
                      },
                      expression: "purchase.delivery_addresses",
                    },
                  }),
                  _c("form-row-edit-text", {
                    attrs: {
                      label:
                        "График поставки товаров (выполнения работ, оказания услуг)",
                    },
                    model: {
                      value: _vm.purchase.add_delivery_schedule_info,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.purchase,
                          "add_delivery_schedule_info",
                          $$v
                        )
                      },
                      expression: "purchase.add_delivery_schedule_info",
                    },
                  }),
                  _c("form-row-edit-date", {
                    attrs: {
                      disabled: !_vm.purchase.planned_contract_date,
                      "min-date": _vm.purchase.planned_contract_date,
                      v: _vm.$v.purchase.max_delivery_date,
                      label:
                        "Максимальный срок поставки товаров (выполнения работ, оказания услуг)",
                    },
                    model: {
                      value: _vm.purchase.max_delivery_date,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "max_delivery_date", $$v)
                      },
                      expression: "purchase.max_delivery_date",
                    },
                  }),
                ],
                2
              ),
              _c(
                "b-tab",
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("purchase-tab-block", {
                        attrs: {
                          errors: _vm.tab3errs,
                          number: "03",
                          title: "Спецификация и документы",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("form-row-single-select", {
                    attrs: {
                      options: _vm.contractFormats,
                      v: _vm.$v.purchase.contract_format_id,
                      label: "Формат заключения договора",
                      placeholder: "Выберите формат заключения договора",
                    },
                    model: {
                      value: _vm.purchase.contract_format_id,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "contract_format_id", $$v)
                      },
                      expression: "purchase.contract_format_id",
                    },
                  }),
                  _vm.purchase.contract_format_id === 1
                    ? _c("form-row-edit-text", {
                        attrs: {
                          label: "Оператор ЭДО",
                          v: _vm.$v.purchase.contract_system_name,
                        },
                        model: {
                          value: _vm.purchase.contract_system_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.purchase, "contract_system_name", $$v)
                          },
                          expression: "purchase.contract_system_name",
                        },
                      })
                    : _vm._e(),
                  _c("form-row-specification-table", {
                    attrs: {
                      "spec-error-message": _vm.specErrorMessage,
                      "spec-errors": _vm.specErrors,
                      "spec-sum": _vm.specSum,
                      v: _vm.$v.purchase.specs,
                      "with-count-edit": "",
                      "with-price": "",
                    },
                    model: {
                      value: _vm.purchase.specs,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "specs", $$v)
                      },
                      expression: "purchase.specs",
                    },
                  }),
                  _c("form-row-single-document-upload", {
                    attrs: {
                      v: _vm.$v.purchase.main_file,
                      required: "",
                      title: "Проект договора",
                    },
                    model: {
                      value: _vm.purchase.main_file,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "main_file", $$v)
                      },
                      expression: "purchase.main_file",
                    },
                  }),
                  _c("form-row-multiple-document-upload", {
                    attrs: { title: "Дополнительные документы" },
                    model: {
                      value: _vm.purchase.files,
                      callback: function ($$v) {
                        _vm.$set(_vm.purchase, "files", $$v)
                      },
                      expression: "purchase.files",
                    },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "my-3 mt-5" },
            [
              _c(
                "b-col",
                [
                  _vm.tabIndex === 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mx-2 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.onCancel },
                        },
                        [_vm._v("Отменить")]
                      )
                    : _vm._e(),
                  _vm.tabIndex > 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mx-2 text-uppercase",
                          attrs: { variant: "custom-outline-secondary" },
                          on: { click: _vm.tabPrevious },
                        },
                        [_vm._v("Назад")]
                      )
                    : _vm._e(),
                  _vm.tabIndex < 2
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mx-2",
                          attrs: { variant: "custom-green" },
                          on: { click: _vm.tabNext },
                        },
                        [_vm._v("Далее")]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "text-uppercase mx-2",
                      attrs: { variant: "custom-danger" },
                      on: { click: _vm.saveDraft },
                    },
                    [_vm._v("Сохранить черновик")]
                  ),
                  _vm.tabIndex === 2
                    ? _c(
                        "b-button",
                        {
                          staticClass: "text-uppercase mx-2",
                          attrs: {
                            disabled:
                              !_vm.isValid || !_vm.$store.getters.getCanSign,
                            variant: "custom-green",
                          },
                          on: { click: _vm.validateForm },
                        },
                        [_vm._v("Подписать и сохранить")]
                      )
                    : _vm._e(),
                  _vm.tabIndex === 2 && !_vm.$store.getters.getCanSign
                    ? _c("span", { staticClass: "text-danger pt-1" }, [
                        _vm._v(
                          "Подписание возможно только при авторизации при помощи ЭЦП"
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-modal",
            {
              ref: "sign-form-modal",
              attrs: {
                centered: "",
                "no-close-on-backdrop": "",
                "no-close-on-esc": "",
                scrollable: "",
                size: "lg",
                title: "Подписать заявку",
              },
              on: { close: _vm.cancelSignModal },
              model: {
                value: _vm.showSignModal,
                callback: function ($$v) {
                  _vm.showSignModal = $$v
                },
                expression: "showSignModal",
              },
            },
            [
              _c(
                "div",
                { staticClass: "d-block text-left" },
                [
                  _c(
                    "b-tabs",
                    { attrs: { "content-class": "mt-3 px-3" } },
                    [
                      _c(
                        "b-tab",
                        { attrs: { active: "" } },
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Форма")]
                            ),
                          ]),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Заказчик"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Наименование заказчика")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentOrganization.full_name)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("ИНН Заказчика")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [_vm._v(_vm._s(_vm.currentOrganization.inn))]
                              ),
                            ],
                            1
                          ),
                          _vm.currentOrganization.kpp
                            ? _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v("КПП Заказчика")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentOrganization.kpp)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.currentOrganization.ogrn
                            ? _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [_vm._v("ОГРН Заказчика")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentOrganization.ogrn)
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Адрес")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.currentOrganization.post_address)
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Дополнительная контактная информация")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.purchase.add_contact_info) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Закупка"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Предмет закупки")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.purchaseCategoryName) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Вид оплаты")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.paymentTypeName) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Условия оплаты")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.paymentTermName) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    "Время продолжительности закупочной сессии (в часах)"
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.purchase.purchase_length) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v("Планируемая дата заключения договора")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDateFormat(
                                          _vm.purchase.planned_contract_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          !!_vm.purchase.planned_contract_execution_date
                            ? _c(
                                "b-row",
                                { staticClass: "border-bottom py-3" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "fs-14 grey-color d-flex align-items-center",
                                      attrs: { md: "3" },
                                    },
                                    [
                                      _vm._v(
                                        "Планируемая дата исполнения договора"
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "fs-14 grey-color",
                                      attrs: { md: "9" },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getDateFormat(
                                              _vm.purchase
                                                .planned_contract_execution_date
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Информация о возможности и случаях одностороннего расторжения сделки в соответствии с действующим законодательством Российской Федерации "
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.add_termination_info
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Начальная максимальная цена контракта (руб.)"
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$formatPrice(
                                          _vm.purchase.start_price
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Требования к поставщикам"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Отсутствие в реестре недобросовестных поставщиков"
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.only_not_rnp ? "ДА" : "НЕТ"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Только для СМП")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.only_msp ? "ДА" : "НЕТ"
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "fs-32 grey-color my-4" }, [
                            _vm._v("Условия поставки"),
                          ]),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Адреса для доставки")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                _vm._l(
                                  _vm.purchase.delivery_addresses,
                                  function (addr, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(" - "),
                                      _c("strong", [_vm._v(_vm._s(addr))]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " График поставки товаров (выполнения работ, оказания услуг)"
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.purchase.add_delivery_schedule_info
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [
                                  _vm._v(
                                    " Маскимальный срок поставки товаров (выполнения работ, оказания услуг)"
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDateFormat(
                                          _vm.purchase.max_delivery_date
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "border-bottom py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Спецификации")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                _vm._l(
                                  _vm.purchase.specs,
                                  function (sp, index) {
                                    return _c("div", { key: index }, [
                                      _vm._v(" - "),
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(sp.name) +
                                            " " +
                                            _vm._s(sp.count) +
                                            " " +
                                            _vm._s(sp.okei.text)
                                        ),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "py-3" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "fs-14 grey-color d-flex align-items-center",
                                  attrs: { md: "3" },
                                },
                                [_vm._v(" Документы")]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "fs-14 grey-color",
                                  attrs: { md: "9" },
                                },
                                [
                                  !!_vm.purchase.main_file
                                    ? _c("div", [
                                        _vm._v(" - "),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.purchase.main_file.name
                                            ) +
                                              " (" +
                                              _vm._s(
                                                _vm.purchase.main_file.path
                                              ) +
                                              ")"
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm._l(
                                    _vm.purchase.files,
                                    function (dc, index) {
                                      return _c("div", { key: index }, [
                                        _vm._v(" - "),
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(dc.name) +
                                              " (" +
                                              _vm._s(dc.path) +
                                              ")"
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-tab",
                        [
                          _c("template", { slot: "title" }, [
                            _c(
                              "span",
                              { staticClass: "system_link-style fs-16" },
                              [_vm._v("Xml представление")]
                            ),
                          ]),
                          !_vm.isSignedXml
                            ? _c("b-form-textarea", {
                                attrs: { disabled: "", rows: "20" },
                                model: {
                                  value: _vm.formXml,
                                  callback: function ($$v) {
                                    _vm.formXml = $$v
                                  },
                                  expression: "formXml",
                                },
                              })
                            : _vm._e(),
                          _vm.isSignedXml
                            ? _c("b-form-textarea", {
                                attrs: { disabled: "", rows: "20" },
                                model: {
                                  value: _vm.signedFormXml,
                                  callback: function ($$v) {
                                    _vm.signedFormXml = $$v
                                  },
                                  expression: "signedFormXml",
                                },
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "py-5 px-3" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "fs-14 grey-color d-flex align-items-center",
                          attrs: { md: "3" },
                        },
                        [_vm._v(" Сертификат")]
                      ),
                      _c(
                        "b-col",
                        { staticClass: "fs-14 grey-color", attrs: { md: "9" } },
                        [
                          _c("b-input", {
                            attrs: {
                              value: _vm.currentCertSubject,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "w-100 px-3",
                  attrs: { slot: "modal-footer" },
                  slot: "modal-footer",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mr-2 text-uppercase",
                      attrs: {
                        disabled: _vm.isDataSending,
                        variant: "custom-outline-secondary",
                      },
                      on: { click: _vm.cancelSignModal },
                    },
                    [_vm._v("Отмена")]
                  ),
                  !_vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isSigningXml,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: _vm.certIndex == null,
                                variant: "custom-green",
                              },
                              on: { click: _vm.signForm },
                            },
                            [_vm._v("Подписать")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isSignedXml
                    ? _c(
                        "b-overlay",
                        {
                          staticClass: "d-inline-block",
                          attrs: {
                            show: _vm.isDataSending,
                            opacity: "0.6",
                            rounded: "",
                            "spinner-small": "",
                            "spinner-variant": "primary",
                          },
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "text-uppercase",
                              attrs: {
                                disabled: _vm.certIndex == null,
                                variant: "custom-green",
                              },
                              on: { click: _vm.sendData },
                            },
                            [_vm._v("Отправить")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }