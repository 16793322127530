<template>
    <div>
        <p class="fs-32 grey-color my-4">{{ title }}</p>

        <b-row>
            <b-col class="d-flex justify-content-between align-items-center flex-row-reverse">
                <file-uploader :is-main="false" @set_file="addFile">Загрузить</file-uploader>
            </b-col>
        </b-row>
        <b-row class="py-3" no-gutters>
            <b-col class="fs-14 grey-color">
                <table class="table">
                    <thead>
                        <tr>
                            <th width="1"></th>
                            <th>Наименование</th>
                            <th width="1"></th>
                        </tr>
                    </thead>
                    <tbody v-if="documents && documents.length">
                        <tr v-for="(file, index) in documents" :key="index">
                            <td>{{ index + 1 }}</td>
                            <td>
                                <b-button variant="link" size="xs" style="padding-top: 0; padding-bottom: 0; color: var(--green-color) !important" class="fs-14" @click="$commonDocumentDownload(file)">{{ file.name }}</b-button>
                            </td>
                            <td>
                                <b-button @click="removeFile(index, false)" variant="light" size="sm" style="background: transparent">
                                    <img src="/images/trash_icon.svg" alt="trash_icon" />
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="3" align="center">Не добавлено документов</td>
                        </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: 'FormRowMultipleDocumentUpload',
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: Array
        },
        headerClass: {
            type: String,
            default: 'fs-32'
        }
    },
    data() {
        return {
            visible: [],
            tmpDocuments: []
        };
    },
    methods: {
        addFile(file) {
            this.tmpDocuments = this.documents;
            this.documents.push(file);
            this.documents = this.tmpDocuments;
        },
        removeFile(index) {
            this.tmpDocuments = this.documents;
            this.tmpDocuments.splice(index, 1);
            this.documents = this.tmpDocuments;
        }
    },
    computed: {
        documents: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
};
</script>

<style scoped>
.table {
    border-collapse: collapse;
    margin-bottom: 0;
}

.is-invalid {
    border: 1px solid red;
    border-radius: 2px;
}

.table thead th {
    background: #e4e6e8;
    font-size: 14px;
    font-weight: 600;
    padding: 12px;
    color: var(--grey-color);
}

.table tbody td {
    font-size: 14px;
    /*font-weight: 600;*/
    padding: 12px;
    vertical-align: middle;
    border-bottom: 1px solid #dee2e6;
    color: var(--grey-color);
}
</style>
