<template>
    <div>
        <b-container fluid class="main_layer py-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mb-4 mt-2 if-tablet">{{ title }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="fs-14 greyBg-color mb-1">&laquo;{{ currentOrganization.short_name }}&raquo;</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="fs-14 greyBg-color">
                            Пользователь:
                            {{ currentUser.name }}
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'PurchaseHeaderBlock',
    props: {
        title: {
            type: String
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'getUser',
            currentOrganization: 'getOrganization'
        })
    }
};
</script>

<style scoped>
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}
</style>
