var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-items-center head_register-list" },
    [
      _c("span", { staticClass: "fs-52 fw-600" }, [_vm._v(_vm._s(_vm.number))]),
      _c("span", { staticClass: "pl-2 fs-18 position-relative" }, [
        _vm._v(" " + _vm._s(_vm.title) + " "),
        !!_vm.errors
          ? _c("span", { staticClass: "badge badge-danger" }, [
              _vm._v(_vm._s(_vm.errors)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }