<template>
    <div v-if="!isLoading">
        <b-container fluid class="main_layer py-4">
            <b-container>
                <b-row>
                    <b-col>
                        <p class="fs-52 fw-600 white-color mt-3 mb-3">№ {{ purchase.reg_number }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <p class="white-color text-uppercase fs-14">{{ purchase.organization_name }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <ul class="purchase_info-list mt-5">
                            <li>
                                <router-link replace :to="{ name: 'Fz223PurchaseOrderInfo' }" exact>Информация о закупке</router-link>
                            </li>
                            <!--              <li>-->
                            <!--                <router-link replace :to="{ name: 'Fz223ProposalDynamics' }" exact>Динамика предложений</router-link>-->
                            <!--              </li>-->
                            <!--              <li v-if="purchase.conditions.has_protocol">-->
                            <!--                <router-link replace :to="{ name: 'Fz223FinalProtocol' }" exact>Итоговый протокол</router-link>-->
                            <!--              </li>-->
                            <!--              <li-->
                            <!--                v-if="-->
                            <!--                  (purchase && purchase.contract) ||-->
                            <!--                  ($store.getters.isLoggedIn &&-->
                            <!--                    !$store.getters.isProvider &&-->
                            <!--                    purchase.organization.id === $store.state.user.organization_id &&-->
                            <!--                    purchase.status_id === PURCHASE_STATUS.status_protocol_formed)-->
                            <!--                "-->
                            <!--              >-->
                            <!--                <router-link replace :to="{ name: 'Contract' }" exact>Договор</router-link>-->
                            <!--              </li>-->
                        </ul>
                    </b-col>
                </b-row>
            </b-container>
        </b-container>
        <router-view :purchase="purchase" @refresh="fillData"></router-view>
    </div>
</template>

<script>
export default {
    name: 'Fz223PurchaseShow',
    data() {
        return {
            isLoading: true,
            purchase: null
        };
    },
    created() {
        this.fillData();
    },
    methods: {
        async fillData() {
            this.purchase = await this.$api.fz223.purchases.get(this.purchaseId).catch((error) => {
                if (error && error.response && error.response.status === 404) {
                    this.$router.push({ name: '404' }).catch(() => {});
                }
            });
            this.isLoading = false;
        }
    },
    computed: {
        purchaseId() {
            return parseInt(this.$route.params.id);
        }
    }
};
</script>

<style scoped>
a:hover {
    color: #fff !important;
}
.main_layer {
    background-image: url('/images/purchases_head.svg');
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 350px;
}
.purchase_info-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    width: 100%;
}
.purchase_info-list li {
    width: 100%;
}
.purchase_info-list li a {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    font-size: 18px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    padding: 20px 0;
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
}
.router-link-active {
    border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
    color: rgba(255, 255, 255, 1) !important;
}
</style>
