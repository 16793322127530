<template>
    <div class="d-flex align-items-center head_register-list">
        <span class="fs-52 fw-600">{{ number }}</span>
        <span class="pl-2 fs-18 position-relative">
            {{ title }}
            <span class="badge badge-danger" v-if="!!errors">{{ errors }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'PurchaseTabBlock',
    props: {
        number: {
            type: String
        },
        title: {
            type: String
        },
        errors: {
            type: Number,
            default: 0
        }
    }
};
</script>
