var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading
    ? _c(
        "div",
        [
          _c(
            "b-container",
            { staticClass: "main_layer py-4", attrs: { fluid: "" } },
            [
              _c(
                "b-container",
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "fs-52 fw-600 white-color mt-3 mb-3" },
                          [_vm._v("№ " + _vm._s(_vm.purchase.reg_number))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          { staticClass: "white-color text-uppercase fs-14" },
                          [_vm._v(_vm._s(_vm.purchase.organization_name))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c("b-col", [
                        _c("ul", { staticClass: "purchase_info-list mt-5" }, [
                          _c(
                            "li",
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    replace: "",
                                    to: { name: "Fz223PurchaseOrderInfo" },
                                    exact: "",
                                  },
                                },
                                [_vm._v("Информация о закупке")]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("router-view", {
            attrs: { purchase: _vm.purchase },
            on: { refresh: _vm.fillData },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }