var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "fs-32 grey-color my-4" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            {
              staticClass:
                "d-flex justify-content-between align-items-center flex-row-reverse",
            },
            [
              _c(
                "file-uploader",
                { attrs: { "is-main": false }, on: { set_file: _vm.addFile } },
                [_vm._v("Загрузить")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "py-3", attrs: { "no-gutters": "" } },
        [
          _c("b-col", { staticClass: "fs-14 grey-color" }, [
            _c("table", { staticClass: "table" }, [
              _c("thead", [
                _c("tr", [
                  _c("th", { attrs: { width: "1" } }),
                  _c("th", [_vm._v("Наименование")]),
                  _c("th", { attrs: { width: "1" } }),
                ]),
              ]),
              _vm.documents && _vm.documents.length
                ? _c(
                    "tbody",
                    _vm._l(_vm.documents, function (file, index) {
                      return _c("tr", { key: index }, [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "fs-14",
                                staticStyle: {
                                  "padding-top": "0",
                                  "padding-bottom": "0",
                                  color: "var(--green-color) !important",
                                },
                                attrs: { variant: "link", size: "xs" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$commonDocumentDownload(file)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(file.name))]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "td",
                          [
                            _c(
                              "b-button",
                              {
                                staticStyle: { background: "transparent" },
                                attrs: { variant: "light", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile(index, false)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: "/images/trash_icon.svg",
                                    alt: "trash_icon",
                                  },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    }),
                    0
                  )
                : _c("tbody", [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "3", align: "center" } }, [
                        _vm._v("Не добавлено документов"),
                      ]),
                    ]),
                  ]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }